"use strict";

const redirects = {
  isRequired: () => {
    throw new Error("Session es requerido");
  },

  redirectTo: (location = null) => {
    return {
      redirect: {
        permanent: false,
        destination: location ? location : "/login",
      },
    };
  },

  redirectToNotFound: () => {
    return {
      redirect: {
        permanent: false,
        destination: location ? location : "/404",
      },
    };
  },

  guest: () => {
    return redirects.redirectTo("/home");
  },

  auth: () => {
    return redirects.redirectTo("/login");
  },

  toEvent: (status, url = null, url2 = null) => {
    return redirects.redirectTo(`/login/?status=${status}${url ? `&url=${url}` : ""}${url2 ? `&url2=${url2}` : ""}`);
  },

  protected: () => {
    return redirects.redirectTo("forbidden");
  },

  chat: (session, role) => {
    //console.log(  Number(session.user.image.role) === role  )
    return Number(session.user.image.role) === role || Number(session.user.image.role) === 5 ? true : false;
  },

  isAdmin: session => {
    return Number(session.user.image.role) >= 5 ? true : false;
  },

  canVisit: session => {
    return (session.user.image.license_verified === "null" || session.user.image.license_verified) === "false" ? false : true;
  },
};

export default redirects;
